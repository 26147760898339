import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "orderNumber",
      model: "orderNumber",
      required: false,
      placeholder: "orderNumber",
      max: 128,
      min: 4,
      validator: [
        validators.string.locale({
          textTooSmall: i18n.t("orderNumberMustBeMin{1}"),
          textTooBig: i18n.t("orderNumberMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "select",
      label: "status",
      model: "statuses.id",
      required: false,
      strict: true,
      selectOptions: {
        noneSelectedText: i18n.t("chooseStatus"),
      },
      values: [],
    },
    {
      type: "input",
      inputType: "text",
      label: "orderTown",
      model: "forms.town",
      required: false,
      placeholder: "orderTown",
      max: 128,
      validator: [
        validators.string.locale({
          textTooSmall: i18n.t("orderTownMustBeMin{1}"),
          textTooBig: i18n.t("orderTownMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "orderAddress",
      model: "forms.address",
      required: false,
      placeholder: "orderAddress",
      max: 128,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("orderAddressMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "orderName",
      model: "forms.name",
      required: false,
      placeholder: "orderName",
      max: 128,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("orderNameMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "select",
      label: "userRole",
      model: "role",
      required: false,
      selectOptions: {
        noneSelectedText: i18n.t("userChooseRole"),
      },
      values: [
        { name: i18n.t("Administrator"), id: "admin" },
        { name: i18n.t("Moderator"), id: "publisher" },
        { name: i18n.t("Farmer"), id: "farmer" },
        { name: i18n.t("Customer"), id: "customer" },
      ],
    },
    {
      type: "input",
      inputType: "text",
      label: "orderPhone",
      model: "forms.phone",
      required: false,
      placeholder: "orderPhone",
      max: 128,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("orderPhoneMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "orderEmail",
      model: "forms.email",
      required: false,
      placeholder: "orderEmail",
      max: 128,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("orderEmailMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "datepicker",
      label: "orderDeliveryDate",
      model: "deliveryDates.date",
      required: false,
      placeholder: "orderDeliveryDate",
      min: null,
      validator: [
        validators.date.locale({
          invalidDate: i18n.t("invalidDate"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "orderReason",
      model: "forms.reason",
      required: false,
      placeholder: "orderReason",
      max: 128,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("orderReasonMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "select",
      label: "manager",
      model: "managers.id",
      required: false,
      strict: true,
      selectOptions: {
        noneSelectedText: i18n.t("chooseManager"),
      },
      values: [],
    },
    {
      type: "select",
      label: "driver",
      model: "drivers.id",
      required: false,
      strict: true,
      selectOptions: {
        noneSelectedText: i18n.t("chooseDriver"),
      },
      values: [],
    },
    {
      type: "select",
      label: "paymentMethod",
      model: "paymentMethods.id",
      required: false,
      strict: true,
      selectOptions: {
        noneSelectedText: i18n.t("choosePaymentMethod"),
      },
      values: [],
    },
  ]
};
