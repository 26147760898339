import moment from 'moment';
import { actions } from "../../../components/grid-views/standart-actions-without-view";
import {APIService} from "../../../services/api";
import i18n from "@/plugins/i18n";

export const columns = [
  {
    label: 'orderNumber',
    field: 'orderNumber',
    width: '5%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'town',
    field: 'forms.town',
    formatRowValue: true,
    formatFn: (value) => {
      return value.forms.length > 0 ? value.forms[0].town : '';
    },
    width: '10%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'address',
    field: 'forms.address',
    formatRowValue: true,
    formatFn: (value) => {
      return value.forms.length > 0 ? value.forms[0].address : '';
    },
    width: '24%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'email',
    field: 'forms.email',
    formatRowValue: true,
    formatFn: (value) => {
      return value.forms.length > 0 ? value.forms[0].email : '';
    },
    width: '11%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'date',
    field: 'deliveryDates.date',
    width: '8%',
    formatRowValue: true,
    formatFn: (value) => {
      return value.deliveryDates.length > 0 ? moment(value.deliveryDates[0].date).format('DD.MM.YYYY') : '';
    },
    filterOptions: {
      enabled: true,
      date: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'manager',
    field: 'managers.id',
    formatRowValue: true,
    formatFn: (value) => {
      return value.managers.length > 0 ? value.managers[0].title : '';
    },
    width: '10%',
    filterOptions: {
      enabled: true,
      strict: true,
      placeholder: i18n.t('chooseManager'),
      filterValue: null,
      filterDropdownItems: [],
    },
  },
  {
    label: 'status',
    field: 'statuses.id',
    formatRowValue: true,
    formatFn: (value) => {
      return value.statuses.length > 0 ? value.statuses[0].title : '';
    },
    width: '11%',
    filterOptions: {
      enabled: true,
      strict: true,
      placeholder: i18n.t('chooseStatus'),
      filterValue: null,
      filterDropdownItems: [],
    },
  },
  {
    label: '',
    field: 'actions',
    width: '12%',
    sortable: false,
    actions: [
      {
        type: 'status',
        label: '',
        classCallback: (row) => {
          return row.statuses.length > 0 ? `fa ${row.statuses[0].icon}` : 'fa fa-check';
        },
        action: {
          type: 'callback',
          callbackFunction: async (_list, data, actionsParams, refresh, eventBus) => {
            const statuses = await APIService.get(`status`);
            const statusIndex = statuses.findIndex(status => status.id === data.statuses[0].id);
            let status;
            if (statusIndex > -1) {
              status = statusIndex < statuses.length - 2 ? statuses[statusIndex + 1] : statuses[statuses.length - 1];
            } else {
              status = statuses[0];
            }
            data.statuses[0] = status;
            try {
              const res = await APIService.put(APIService.getRestFullEntityUrl(actionsParams.url, data), data);
              if (!res) {
                throw new Error('Error on status save');
              }
              eventBus.$emit('alert', {
                type: 'success',
                text: i18n.t('statusWasSuccessfullySaved'),
              });
            } catch (e) {
              eventBus.$emit('alert', {
                type: 'danger',
                text: i18n.t('errorOnStatusSaving'),
              });
            }
            refresh();
          },
        },
      },
      {
        type: 'view',
        label: '',
        class: 'fa fa-eye',
        action: {
          type: 'route',
          paramsResolver: (data) => {
            return {'id': data.id}
          },
          nameResolver: () => {
            return 'ViewOrder';
          }
        },
      },
      ...actions,
      {
        type: 'print',
        label: '',
        class: 'fa fa-print',
        action: {
          type: 'route',
          paramsResolver: (data) => {
            return {'id': data.id}
          },
          nameResolver: () => {
            return 'PrintOrder';
          }
        },
      },
      {
        type: 'view',
        label: '',
        class: 'fa fa-list-ol',
        action: {
          type: 'route',
          paramsResolver: (data) => {
            return {'id': data.id}
          },
          nameResolver: () => {
            return 'OrderHistoryList';
          }
        },
      },
    ]
  },
]
