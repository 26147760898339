<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('ordersList') }}</h2>
      <grid-view-table
        :id="'orders'"
        :columns="columns"
        :extended-filter-schema="extendedFilterSchema"
        :path="'order'"
        :update-route="'UpdateOrder'"
        :extra-params="'&join=deliveryDates&&join=forms&join=managers&join=statuses&join=drivers&join=paymentMethods'"
        :rowStyleClassFn="rowStyleClassFn"
        :default-sort="'createdAt,DESC'"
      />
    </div>
  </div>
</template>

<script>
  import GridViewTable from '../../components/GridViewTable';
  import { columns } from './grid-views/orders';
  import { schema as extendedFilterSchema } from './filter-forms-schemas/order';
  import {APIService} from "../../services/api";

  export default {
    name: 'OrderList',
    components: {
      GridViewTable,
    },
    data: () => {
      return {
        columns,
        extendedFilterSchema,
      }
    },
    methods: {
      rowStyleClassFn(row) {
        return row.statuses.length>0 ? row.statuses[0].token : '';
      },
      fillFilterFormSelectValues(fieldName, optionValues) {
        const schemaField = this.extendedFilterSchema.fields.find(field => field.model === fieldName);
        schemaField.values = optionValues.map(option => {
          return {
            name: option.title,
            id: option.id,
          }
        });
      },
      fillGridFilterSelectValues(fieldName, optionValues) {
        const columnIndex = this.columns.findIndex(column => column.field === fieldName);
        if (columnIndex > -1) {
          this.columns[columnIndex].filterOptions.filterDropdownItems = optionValues.map(option => {
            return {
              text: option.title,
              value: option.id,
            }
          });
        }
      }
    },
    async beforeMount() {
      try {
        const statuses = await APIService.get('status?sort=id,ASC');
        const managers = await APIService.get('manager?sort=title,ASC');
        const drivers = await APIService.get('driver?sort=title,ASC');
        const paymentMethods = await APIService.get('payment-method?sort=title,ASC');
        this.fillFilterFormSelectValues('statuses.id', statuses);
        this.fillFilterFormSelectValues('managers.id', managers);
        this.fillFilterFormSelectValues('drivers.id', drivers);
        this.fillFilterFormSelectValues('paymentMethods.id', paymentMethods);
        this.fillGridFilterSelectValues('statuses.id', statuses);
        this.fillGridFilterSelectValues('managers.id', managers);
      } catch (e) {
        console.error(e.toString());
      }
    }
  }
</script>
